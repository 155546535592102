@charset "UTF-8";
/*
  This mixin can be used to set the object-fit:
  @include object-fit(contain);
  or object-fit and object-position:
  @include object-fit(cover, top);
*/
.rating-summary .label {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0;
}

.rating-summary {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  max-height: 100%;
}

/**
 * Loader to be used across the site
 * Use this variable
 */
/**
 * Form Elements config
 */
/**
 * Collection of generic/global variables used across the theme
 */
.account .customer-review.view .product-name {
  font-weight: 700;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  line-height: 1;
}

.rating-summary {
  justify-content: flex-start;
  overflow: hidden;
  white-space: nowrap;
}

.rating-summary .rating-label {
  margin-right: 0.5rem;
  color: #767676;
}

.rating-summary .rating-result {
  position: relative;
  width: 85px;
  height: 16px;
}

.rating-summary .rating-result:before {
  content: "";
  line-height: 1;
  speak: none;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  font-family: "ms-icons";
}

.rating-summary .rating-result > span {
  position: relative;
  display: block;
  height: 16px;
  overflow: hidden;
  z-index: 2;
}

.rating-summary .rating-result > span:before {
  content: "";
  line-height: 1;
  speak: none;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  font-family: "ms-icons";
}

.rating-summary .rating-result > span span {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0;
}

.rating-summary .rating-result:before,
.rating-summary .rating-result > span:before {
  position: absolute;
  top: 0;
  right: auto;
  bottom: auto;
  left: 0;
  display: block;
  color: #dddcdb;
  z-index: 1;
  content: "";
}

.rating-summary .rating-result > span:before {
  z-index: 2;
  color: #ded31e;
}

.account .customer-review.view .product-details {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  max-width: 100%;
  margin-bottom: 1rem;
}

.account .customer-review.view .product-details .product-media {
  order: 2;
  max-width: 100%;
}

.account .customer-review.view .product-details .product-info {
  order: 1;
  max-width: 100%;
  margin-bottom: 0.5rem;
}

.account .customer-review.view .product-details .product-info .rating-average-label {
  display: none;
}

.account .customer-review.view .product-details .product-info .product-reviews-summary {
  display: none;
}

.account .customer-review.view .product-name {
  display: flex;
  align-items: center;
  padding-bottom: 0;
  font-weight: normal;
  letter-spacing: -0.02em;
  border-bottom: 1px solid #e9ecef;
  min-height: 53px;
  color: #1a1a1a;
  margin-bottom: 0.5rem;
}

.account .customer-review.view .product-name {
  font-size: 13px;
}

@media screen and (min-width: 0px) {
  .account .customer-review.view .product-name {
    font-size: calc(13px + 12 * ((100vw - 0px) / 1440));
  }
}

@media screen and (min-width: 1440px) {
  .account .customer-review.view .product-name {
    font-size: 25px;
  }
}

.account .customer-review.view .review-details {
  padding: 1rem 1.5rem;
  border: 1px solid #e9ecef;
  background: #fff;
}

.account .customer-review.view .review-details .title {
  color: #1a1a1a;
  padding-bottom: 0.5rem;
  display: block;
}

.account .customer-review.view .review-details .title {
  font-size: 10px;
}

@media screen and (min-width: 0px) {
  .account .customer-review.view .review-details .title {
    font-size: calc(10px + 10 * ((100vw - 0px) / 1440));
  }
}

@media screen and (min-width: 1440px) {
  .account .customer-review.view .review-details .title {
    font-size: 20px;
  }
}

.account .customer-review.view .review-details .title strong {
  font-weight: normal;
}

@media screen and (max-width: 992px) {
  .account .customer-review.view .review-details .title {
    font-size: 1rem;
  }
}

@media screen and (min-width: 992px) {
  .account .customer-review.view .review-details .title {
    font-weight: normal;
  }
}

.account .customer-review.view .review-details .rating-summary {
  margin-bottom: 1rem;
}

.account .customer-review.view .review-details .review-title {
  font-weight: bold;
}

.account .customer-review.view .review-details .review-content {
  margin-bottom: 1rem;
}

.account .customer-review.view .review-details .review-date {
  font-size: 0.75rem;
  color: #767676;
}

/*.product-reviews-summary,
.table-reviews {
    .rating-summary {
        .label {
           // @include visually-hidden();
        }
    }
}

.review-control-vote {
   // @include lib-rating-vote();
}

//
//    Add review block
//--------------------------------------
.block {
    &.add.review {
        margin-bottom:  $indent-xl;
        > .title {
           @include responsive-text-size('beta');
        }
        .legend {
            @include responsive-text-size('beta');
            margin: 0 0 $indent-base;
        }
        .field.rating {
            display: table-row;
            .label {
                display: table-cell;
                vertical-align: middle;
                padding: $indent-xs 0;
            }
            .control {
                display: table-cell;
                vertical-align: top;
            }
        }
    }
    &.reviews.list {
        > .title {
            @include responsive-text-size('alpha');
        }
        .review {
            &.item {
                margin-bottom:  $indent-xl;
            }
            &.title {
                @include responsive-text-size('beta');
            }
        }
    }
}*/
/*
.data.table.reviews {
    .rating-summary {
        margin-top: -4px;
    }
}

.customer-review.view {
    margin-bottom: spacers(3);

    .review.title,
    .ratings-summary,
    .product-details {
        margin-bottom: spacers(3);
    }
}

.product-reviews-summary {
    display: table;
    margin: spacers(2) 0 12px;

    .products.wrapper.list & {
        margin: 0 auto;
    }
    &.empty {
        margin-left: 0;
    }
    .rating-summary {
        position: relative;
        //left: -$indent-xs;
        display: table-cell;
        vertical-align: middle;
        text-align: left;
    }
    .reviews-actions {
        display: table-cell;
        vertical-align: middle;

        a {
            margin-right: spacers(2);
        }
    }
}



//
//    Desktop
//--------------------------------------
@include respond-to-up('md') {
    .product-reviews-summary {
        .products.wrapper.list & {
            margin: 0;
        }
    }
}

//
//    Common
//--------------------------------------

.customer-review {
    .product-details {
        @include clearfix();

        margin-bottom:  $indent-xl;

        .rating-average-label {
        }
    }
    .product-media {
        width: 30%;
        max-width: 285px;
        float: left;
        margin-right: 3%;
    }
    .product-info {
    }
    .review-details {
        .title {
        }
        .customer-review-rating {
            margin-bottom: $indent-base;

            .item {
                margin-bottom: $indent-s;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
        .review-title {
            @include responsive-text-size('beta');
        }
        .review-content {
            margin-bottom: $indent-base;
        }
        .review-date {
        }
    }
    .product-reviews-summary {
        .rating-summary,
        .reviews-actions {
            display: block;
        }
    }
}

.review-field-ratings {
    .nested {
        display: table;
    }
    .review-field-rating {
        display: table-row;
        .label,
        .control {
            display: table-cell;
            padding-bottom: $indent-s;
            vertical-align: top;
        }
        .label {
            padding-top: 6px;
            padding-right: $indent-s;
        }
        .control {
            padding-top: 2px;
        }
    }
}

//
//    Review product page
//--------------------------------------
.review {
    &-toolbar {
        margin: 0 0 $indent-base;
        text-align: center;

        &:first-child {
            display: none;
        }
        .pages {
            display: block;
            text-align: center;
            padding: $indent-base 0;
        }
        .limiter {
            display: none;
        }
    }
    &-add {
        .block-title {
            display: none;
        }
    }
    &-form {
        .action.submit.primary {
        }
        .actions-toolbar .actions-primary {
        }
    }
    &-fieldset {}
    .fieldset &-legend.legend {
        margin-left: 0;

        strong {
            display: block;
            margin-left: 0;
        }
        margin-bottom: $indent-base;
    }
    .fieldset &-field-ratings {
        > .label {
        }
    }
    &-control-vote label:before,
    &-control-vote:before {
    }
    &-field-rating {
        .label {
        }
        .control {
            margin-top: $indent-s;
            margin-bottom: 1.2* $indent-xl;
        }
    }
    &-list {
        .block-title strong {
            @include responsive-text-size('alpha');
        }
        margin-bottom: $indent-l;
    }
    &-item {
        @include clearfix();
        padding: $indent-base 0;
        margin: 0;
        &:last-child {
            border-width: 0;
        }
    }
    &-ratings {
        display: table;
        max-width: 100%;
        margin-bottom: $indent-s;

        .rating {
            &-summary {
                display: table-row;
            }

            &-label {
                display: table-cell;
                padding-bottom: $indent-xs;
                padding-right: $indent-m;
                padding-top: 1px;
                vertical-align: top;
            }

            &-result {
                display: table-cell;
                vertical-align: top;
            }
        }
    }
    &-author {
        display: inline;
        .review-details-value {
        }
    }
    &-date {
        .review-details-label {
        }
    }
    &-title {
        @include responsive-text-size('beta');
        margin: 0 0 $indent-base;
    }
    &-content {
        margin-bottom: $indent-base;
    }
}


//
//    Desktop
//--------------------------------------
@include respond-to('medium') {
    .review {
        &-form {
            max-width: 500px;
        }

        $review-ratings-left: 280px;
        &-ratings {
            max-width: $review-ratings-left -  $indent-xl;
            float: left;
            margin-bottom: 0;
        }
        &-ratings ~ &-content,
        &-ratings ~ &-details {
            margin-left: $review-ratings-left;
        }

        $reviews-indent-desktop: 30px;
        &-toolbar {
            margin: 0 0 $reviews-indent-desktop;
            .pages {
                padding: $reviews-indent-desktop 0;
            }
        }
        .fieldset &-legend.legend {
            margin-bottom: $reviews-indent-desktop;
        }
        &-item {
            padding: $reviews-indent-desktop 0;
        }
        &-title {
            margin: 0 0 $reviews-indent-desktop;
        }
    }
}
*/
